
import { convertUCWordsOrCamelCaseToDashCase, smoothScrollToTargetId } from '@/helpers/util'

export default {
  name: 'SectionImageOrVideo',
  props: {
    href: {
      required: false,
      type: String,
      default: 'javascript:void(0)'
    },
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    extra: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeThumb: 0,
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
      headerHeight: 0
    }
  },
  computed: {
    heading() {
      return this.data.heading
    },
    welcome() {
      return this.data.welcomeMessage
    },
    hasBackgroundOverlay() {
      return this.data.hasBackgroundOverlay
    },
    overlayOpacity() {
      return this.data.backgroundOverlayOpacity || 0.8
    },
    sliderData() {
      return [
        {
          thumb: this.data.image,
          image: this.data.image,
          video: this.data.video,
          title: this.data.title,
          subtitle: this.data.subtitle
        }
      ]
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener)
    this.headerHeight = document.getElementById('header-project')
      ? document.getElementById('header-project').clientHeight
      : 0
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    goToNextSection() {
      const sections = this.$parent.$parent.sections
      if (sections && sections.length > 1) {
        const targetSection = sections[this.orderNo]
        const targetString = convertUCWordsOrCamelCaseToDashCase(targetSection.type)
        smoothScrollToTargetId(`#${targetString}`)
      }
    },
    handleThumbChange(itemId) {
      const temp = itemId.split('-')
      this.activeThumb = parseInt(temp[1])
    },
    resizeListener(e) {
      this.windowHeight = window.innerHeight
      this.headerHeight = document.getElementById('header-project')
        ? document.getElementById('header-project').clientHeight
        : 0
    }
  }
}
